
.image-container {
    position: relative;
    display: inline-block;
  }
.active>h6{
    font-weight: 600 !important;
}
 
.form-control :disabled{
    background-color: white;
}
.inputBox1{
    background: transparent;
    border: .1px solid #125912;
    border-radius: 0px;
}
.inputBox1::placeholder {
    color: rgb(10 59 10);
    font-size: 14px;
    font-weight: 400;

  }
  .inputBox2{
    background: transparent;
    border: none;
    border-bottom: 0px solid #125912;
    border-radius: 0px;
    padding-left: 0px;
    padding-bottom: 10px;
}

.inputBox2::placeholder {
    color: rgb(10 59 10);
    font-size: 14px;
    font-weight: 600;

  }

  
  .inputBox1:focus{
    outline: none;
}
.inputBox2:focus{
    outline: none;
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: transparent;
}
.inputBox1:focus {
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: transparent;
    /* color: white !important; */
  }
.mob-tabs{
  display: flex;
    flex-direction: row;
    overflow-x: none;
    flex-wrap: nowrap;
    overflow-y: none;
}

.qrCont{
  float: right;
  /* border-left:1px solid; */
}
.formCont{
  padding-right:30px;
  margin-bottom: 0px;
}
.instructIcon{
  height:3%;
  width:3%;
  margin-right:10px
}
.instruct{
  margin-top: -7px;
}

@media only screen and (max-width: 767px) {
  .mob-tabs{
    display: flex;
      flex-direction: row;
      overflow-x: scroll;
      flex-wrap: nowrap;
      overflow-y: none;
  }
  .qrCont{
    float: right;
    border-left:none;
  }
  .formCont{
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 400px) {
  .instructIcon{
    height:8%;
    width:8%;
    margin-right:10px
  }
  .instruct{
    margin-top: -6px;
  }
}