@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@200;500&family=Roboto+Condensed:wght@200;300;500;700&display=swap');
.heroSectionText{
    color:white;
    font-family: 'Dosis';
}
.heroButtons{
    font-family: 'Roboto Condensed';
    font-weight: 300;
}
.HeroSectionRight{
    padding-left:0px;
    padding-right:0px;
    display:block;
}
.HeroSectionLeft{
    padding-left:0px;
    padding-right:0px;
    background-color:rgb(6 40 6);
    display:flex;
    align-items:center;
    justify-content:center;
}


@media only screen and (max-width: 767px) {
    .HeroSectionRight{
        padding-left:0px;
        padding-right:0px;
        display:none;
    }
    .HeroSectionLeft{
        padding-left:0px;
        padding-right:0px;
        background-color:rgb(10 59 10);
        display:flex;
        align-items:center;
        justify-content:center;
        height: 50vh;
    }
}