@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@200;300;500;700&display=swap');
.inputBox{
    background: transparent;
    border: none;
    border-bottom: 1.5px solid #125912;
    border-radius: 0px;
    padding-left: 0px;
    padding-bottom: 10px;
}
.inputBox::placeholder {
    color: rgb(10 59 10);
    padding: 0px;
    font-size: 14px;
    font-weight: 600;
    margin-right: -5px;
  }
  .inputBox:focus{
    outline: none;
}
.inputBox:focus {
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: transparent;
    border-bottom: 1.5px solid #125912;
    /* color: white !important; */
  }
.submitButton{
    border: none;
    background: #76561c;
    padding-top: 3px;
    font-size: 17px;
    padding-bottom: 4px;
    padding-left: 28px;
    padding-right: 28px;
    border-radius: 18px;
    letter-spacing: 0.05rem;
    color:#dfe1e7;
    margin-top: 20px;
}
.submitButton:hover{
    background-color:#76561c ;
}
.submitButton:active{
    background-color: #76561c;
    outline: none;
}
.submitButton:focus{
    background-color: #76561c;
    outline: none;

}

/* Animation Button */
.toggle-container {
    width: 250px;
    height: 35px;
    display: flex;
    border-radius: 25px;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    margin-bottom: 30px;
  }
  
  .toggle-background {
    position: absolute;
    width: 100%;
    border-radius: 30px;
    height: 100%;
    background: linear-gradient(to right, #094709 50%, #fff 50%);

  }
  
  .toggle-text-container {
    display: flex;
    font-family: 'Roboto Condensed';
    letter-spacing: 0.05rem;
    justify-content: space-between;
    width: 100%;
    background-color: #d6d7da;
  }
  
  .toggle-text {
    flex: 1;
    justify-content: space-around;
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    color:white;
    z-index: 1;
    font-weight: 400;
    letter-spacing: 0.09rem;
  }
  
  .login.active {
    z-index: 2;
    color:#125912;
    border-radius: 30px;
    font-family: 'Roboto Condensed';
    font-weight: 400;
    letter-spacing: 0.07rem;
  }
  
  .signup.active {
    z-index: 2;
    color:#125912;
    float: right;
    font-family: 'Roboto Condensed';
    font-weight: 400;
    letter-spacing: 0.09rem;
  }

  .bgImg{
    background-image: url('../../assets//Images/signup.png');
    position: relative;
  background-color: rgba(255,255,255,0.36);
  height: 100vh;
  width: 100vw;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow-x: scroll;

  }
  