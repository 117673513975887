@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@200;300;500;700&display=swap');
.mainContainer{
    background-image: url('../../assets/Images/challangehires.jpg');
    position: relative;
  margin-top: 25px;
  background-color: rgba(255,255,255,0.36);
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.column{
  height: 300px;
  font-family: 'Roboto Condensed';
  background-color: rgb(67 63 63 / 36%);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border-radius:5;
  backdrop-filter: blur(5px);
  font-weight: 300;
}
.column1{
  height: 300px;
  font-family: 'Roboto Condensed';
  background-color: rgb(116 78 78 / 36%);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border-radius:5;
  backdrop-filter: blur(6px);
  font-weight: 300;
  /* overflow-x: scroll; */
}
.liveFeed{
  overflow-x: scroll;
  height: 100%;
}
.liveFeed::-webkit-scrollbar{
  display: none;
}
.column2{
  height: 300px;
  background-color: rgb(203 194 194 / 36%);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border-radius:5;
  backdrop-filter: blur(5px);
  font-family: 'Roboto Condensed';
  font-weight: 300;
}
.column >p{
  font-family: 'Roboto Condensed';
  color: white;
}
.userIcon{
margin-bottom: -11px;
margin-left: -19px;
width: 25px;
}

@media only screen and (max-width: 449px) {

}
