@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@200;300;500;700&display=swap');
.explore{
    font-size: 17px;
    line-height:0.9rem;
    margin-top: 20px;
    color: #fff;

    /* font-family: 'Roboto Condensed'; */
    font-weight: 200;
}
/* font-family: 'Roboto Condensed'; */
.explore > p{
    font-size: 13px;
    margin-bottom: 10px;
    color: #fff;
    font-weight: 200;
}
.explore > h6{
    margin-bottom: 17px;
}
.science{
    font-size: 17px;
    line-height:0.9rem;
    padding-right: 0px !important;
    margin-top: 20px;
    color: #fff;
    /* font-family: 'Roboto Condensed'; */
    font-weight: 200;
}
.science > p{
    font-size: 13px;
    margin-bottom: 10px;
    color: #fff;
    /* font-weight: 300; */
    font-weight: 200;
}
.science > h6{
    margin-bottom: 17px;
}
.about{
    font-size: 17px;
    line-height:0.9rem;
    padding-right: 0px !important;
    margin-top: 20px;
    color: #fff;
    /* font-family: 'Roboto Condensed'; */
    font-weight: 200;
}
.about > p{
    font-size: 13px;
    margin-bottom: 10px;
    color: #fff;
    /* font-weight: 300; */
}
.about > h6{
    margin-bottom: 17px;
}
.help{
    font-size: 17px;
    line-height:0.9rem;
    padding-right: 0px !important;
    margin-top: 20px;
    color: #fff;
    /* font-family: 'Roboto Condensed'; */
    font-weight: 200;
}
.help > p{
    font-size: 13px;
    margin-bottom: 10px;
    color: #fff;
    font-weight: 200;
    /* font-weight: 300; */
}
.help > h6{
    margin-bottom: 17px;
}

.openSourceIcon{
    margin-top: 0px;
}

@media only screen and (max-width: 767px) {
    .openSourceIcon{
        margin-top: 30px;
    }
}