
@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@200;500&family=Roboto+Condensed:wght@200;300;500;700&display=swap');

.HeroContainer{
    padding-left:0px !important;
    font-family: 'Dosis';
    padding-right: 0px !important;
    margin-bottom: 75px;
}
.HeroDiv{
    display: flex;
    height: fit-content;
    background-image: url('../../assets/science/p1.png');
    position: relative;
  height: 80vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: flex-start;
  align-items: center;
}
.HeroDivImg{
    width: 100%;
    height: 80vh;
    object-fit: cover;
}
.MainHeading{
    color: white;
    background-color: #7f3e10e0;
    border-radius: 5px;
    padding:25px 35px 25px 20px;
    margin-left: 50px;
}
.MainHeadingTitle{
    font-weight:600;
    letter-spacing:.1rem;
    font-size:40px
}
.MainHeadingSubtitle{
  letter-spacing:.07rem;
  font-size:26px
}
.placeHolderImg{
    /* height: 100px; */
    width: 100%;
    height: 200px;
    object-fit: cover;
}
.placeHolderImgTitle{
    font-family: 'Dosis';
    font-size: 21px;
    font-weight: 500;
    margin-top: 10px;
    color: black;
}
.placeHolderImgDesc{
    font-family: 'Dosis';
    font-size: 15px;
    font-weight: 400;
    color: #caa10e;
}
.mostRightCont{
    margin-top:15px;
}
.mostLeftCont{
    margin-top:15px;
    padding-right:20px
}

@media only screen and (max-width: 767px) {
    .placeHolderImgTitle{
      margin-left: 10px;
    }
    .placeHolderImg{
       width: 96%;
       margin-left: 10px;
       margin-right: 5px;
    }

    .placeHolderImgDesc{
      margin-left: 10px;
    }
    .MainHeading{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        color: white;
        background-color: #7f3e10e0;
        border-radius: 5px;
        padding:25px 35px 25px 20px;
    }
    .HeroDiv{
        height: 60vh;
    }
    .MainHeading{
        padding:25px 35px 25px 20px;
        margin-left: 0px;
        width: max-content;
        /* margin-left: 10px; */
    }
    .MainHeadingTitle{
        font-weight:600;
        letter-spacing:.1rem;
        font-size:35px
    }
    .MainHeadingSubtitle{
      letter-spacing:.07rem;
      font-size:18px    
    }
    .mostRightCont{
        padding-right:20px
    }
}
@media only screen and (max-width: 375px) {
    .MainHeadingTitle{
        font-weight:600;
        letter-spacing:.1rem;
        font-size:30px
    }
    .MainHeadingSubtitle{
      letter-spacing:.07rem;
      font-size:16px    
    }
}
