.popup-content{
  background: #0a3b0a;
  border: none;
  width: 50%;
}

@media only screen and (max-width: 767px) {
  .popup-content{
    background: #0a3b0a;
    border: none;
    width: 60%;
  }
}

@media only screen and (max-width: 767px) {
  .popup-content{
    background: #0a3b0a;
    border: none;
    width: 70%;
  }
}


@media only screen and (max-width: 450px) {
  .popup-content{
    background: #0a3b0a;
    border: none;
    width: 90%;
  }
}