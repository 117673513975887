
@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@200;500&family=Roboto+Condensed:wght@200;300;500;700&display=swap');
.title1{
    font-family: 'Dosis';
    margin-bottom: 15px; 
    font-size: 35px
}
.title{
    font-weight: 500;
     font-size: 13px;
     display:flex;
     justify-content:flex-end;
     width: 140px;
     margin-left:-8px;
     font-family:'Roboto Condensed'
}
.title2{
    font-weight: 500;
     font-size: 13px;
     display:flex;
     justify-content:center;
     width: 175px;
     font-family:'Roboto Condensed'
}
.title3{
    font-weight: 500;
     font-size: 13px;
     display:flex;
     justify-content:flex-end;
     width: 140px;
     margin-left:-14px;
     font-family:'Roboto Condensed'
}
.title4{
    font-weight: 500;
    font-size: 13px;
    display:flex;
    justify-content:flex-end;
    width: 115px;
    margin-top: 2px;
    margin-left:-14px;
    font-family:'Roboto Condensed'
}