@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@200;300;500;700&display=swap');

.containerHeading{
    font-size:35px;
    color:#ba8938;
    font-family: 'Roboto Condensed';
    font-weight: 300;
}
.imageGroup{
    width: 100px;
    height: 110px;
}

@media only screen and (max-width: 449px) {
    .containerHeading{
        font-size:25px;
    }
    .imageGroup{
        width: 60px;
        height: 70px;
    }
    
}