@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@200;500&family=Roboto+Condensed:wght@200;300;500;700&display=swap');
.title1{
    font-family: 'Dosis';
    margin-bottom: 15px; 
    font-size: 35px
}
.caption1{
    padding-left:50px;
    padding-right:20px;
    margin-top:35px;
    color:#054805;
    font-size:14px;
    line-height:1.5rem;
    text-align: justify;
}
.img1{
    width: 150px;
}
.img2{
    width: 143px;
}


@media only screen and (max-width: 549px) {
    .caption1{
        padding-left:10px;
        padding-right:10px;

    }
    .img1{
        width: 130px;
        margin-bottom: 10px;
    }
    .img2{
        width: 130px;
        margin-bottom: 10px;
    }
    
}