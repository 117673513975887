@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@200;300;500;700&display=swap');

.headerLogo{
    width: 150px;
    margin-right: 10px;
}
.mr-auto .nav-link {
    color: #2d4f2d;
    margin-left: 5px;
    margin-right: 5px;
    font-weight: 400;
    font-family:'Roboto Condensed'
    
}
.topHeader{
    background-color:rgb(226 245 209);
    padding-left:10px;
    padding-right:10px;
    padding:0px;
    height: 28px;
}
.topHeaderLeftSection{
    display:flex;
    flex-direction:row;
    justify-content:center;
    float: left;
    margin-left: 5px;
    font-weight: 300;
    cursor: pointer;
    font-size: 13px;
    font-family:'Roboto Condensed'
}
.topHeaderRightSection{
    display:flex;
    flex-direction:row;
    justify-content:center;
    margin-bottom: 0px;
    float:right;
    align-items: flex-end;
}
.topHeaderRightSection button{
    border:none;
    margin-left:5px;
    margin-right:5px;
    border-radius:5px
}
.topHeaderRightSection p{
    display:flex;
    flex-direction:row;
    justify-content:center;
    color:#124912;
    margin-left:5px;
    margin-right:5px;
    font-weight: 500;
    font-size: 13px;
    cursor: pointer;
    font-family:'Roboto Condensed'
}
.lang{
    background:transparent;
    padding:0px;
}
.lang:focus{
    background: transparent;
    outline: none !important;
    box-shadow: none !important;
    outline-offset: none !important;
}
.lang:hover{
    background: transparent;
}
.lang:active{
    background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
}
.langItem{
    font-size: 13px;
    padding: 4px 10px;
}
.langItem:active{
    background-color: #0a3b0a !important;
    outline: none !important; 
}
.langItem:focus{
    background-color: #0a3b0a !important;
    outline: none !important; 
}

@media only screen and (max-width: 767px) {
    .topHeader{
        background-color:rgb(226 245 209);
        padding-left:10px;
        padding-right:10px;
        padding:0px;
        height: 80px;
    }
    .topHeaderLeftSection{
        margin-top:10px;
        display:flex;
        flex-direction:row;
        justify-content:center;
        float: none;
    }
    .topHeaderRightSection{
        display:flex;
        flex-direction:row;
        justify-content:center;
        align-items:flex-start;
        margin-top:0px;
        /* margin-bottom: 5px; */
        float:none;
    }
  }
  @media only screen and (max-width: 449px) {
    .topHeaderLeftSection{
        margin-top:10px;
        display:flex;
        flex-direction:row;
        justify-content:center;
        float: none;
        font-size: 13px;
    }
  }