@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@200;300;500;700&display=swap');
.heroSection4Text{
    color:white;
    font-family: 'Roboto Condensed';
}
.mainImage{
    width: 101%;
    max-width: 101%;
    object-fit: cover;
}
.HeroSection4Right{
    padding-left:0px;
    padding-right:0px;
    background-color:#6b5609;
    display:flex;
    align-items:center;
    justify-content:center;
}

@media only screen and (max-width: 767px) {
    .heroSection4Text{
        color:white;
        font-family: 'Roboto Condensed';
        margin-top: 10px;
    }
    .HeroSection4Right{
        margin-bottom: 10px;
        background-color:#6b5609;
        display:flex;
        align-items:center;
        justify-content:center;
        padding: 15px;
        margin-top: -1px;
    }
    .mainImage{
        width: 100%;
        max-width: 100%;
        object-fit: cover;
        height: 20vh;
    }

    

}